body .Site{
	/* max-height: 200px; */
    display: flex;
    flex-direction: column;
    padding: 15px;
}
body .Site .top{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 100px;
	gap: 10px;
	width: calc(100% - 10px);
	opacity: 1;
    width: 100%;
    margin-bottom: 10px;
}
body .Site .mirrors{
    width: 100%;
}
body .Site .name,
body .Site .secret,
body .Site .ballance {
	display: flex;
	justify-content: flex-start;
}
body .Site.onDeleting {
	opacity: 0.5;
	transition: 500ms;
	padding: 0;
	margin: 0;
	max-height: 0px;
	overflow: hidden;
}
.Site .MuiFormControl-root{
    width: 100%;
}
