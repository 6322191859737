.Analitics {
    width: 100%;
    background-color: #fff;
    padding: 15px;
    min-height: 100vh;
}
.Analitics .top{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}
.Analitics .graph{
    color: black;
    /* height: 500px; */
}
.Analitics .siteslist ul{
}
.Analitics .siteslist ul li{
    text-align-last: left;
}
.Analitics .siteslist ul li span{
    /* width: 300px; */
    margin-right: 15px;
}
.chart-wrap{
    width: 100%;
    height: 400px;
}

.siteslist{
    display: flex;
    flex-wrap: wrap;
    color: black;
}
.siteslist--site{
}
.siteslist-price{
    display: inline-flex;
    min-width: 100px;
}