body .Wallet {
	display: grid;
	grid-template-columns: 140px 1fr 1fr 100px 100px;
	gap: 10px;
	width: calc(100% - 10px);
	opacity: 1;
	max-height: 200px;

	background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    border-radius: 5px;
    color: white;
}
.Wallet .MuiInput-formControl{
    color: inherit;
}
.Wallet .MuiFormLabel-root.Mui-focused{
    color: white;
}

body .Wallet .name,
body .Wallet .secret,
body .Wallet .ballance {
	display: flex;
	justify-content: flex-start;
}
body .Wallet .delete {
    align-self: center;
}
body .Wallet.onDeleting {
	opacity: 0.5;
	transition: 500ms;
	padding: 0;
	margin: 0;
	max-height: 0px;
	overflow: hidden;
}
.Wallet .MuiFormControl-root {
	width: 100%;
}
