.History {
}

.History .items {
}
.History .item {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
.History .item .status{
    text-align-last: left;
    padding: 5px;
    border-radius: 5px;
}
.History .item.success .status{
	background-color: #85ffbd;

}
.History .item.error {
}

